<template>
  <CustomErrorCard
  :mainText="'error.notFound'"
  />
</template>

<script>
import CustomErrorCard from "@/components/CustomErrorCard";

export default {
  name: "Error404",
  components: { CustomErrorCard },
};
</script>
